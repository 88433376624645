.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*:disabled {
  opacity: 0.5;
}

.home-small-text {
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 300;
  padding: 0;
}

.home-big-text {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 700;
}

.home-button {
  width: 100%;
  margin-top: 14px;
  max-width: 161.15px;
  max-height: 29.93px;
  font-size: 19.34px;
  font-weight: 700;
  background-color: #00214c;
  box-shadow: 0px 0px 10.7431px rgba(0, 0, 0, 0.25);
  border-radius: 5.37153px;
  color: white;
  padding: 0;
}

.hover-icon:hover + .text-icon {
  visibility: visible !important;
}

/* OVERRIDE CALENBDAR */

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: inherit !important;
  color: #00214c;
}

#root > div.h-screen.relative.p-6 > div > div.rbc-toolbar > span:nth-child(3) {
  background-color: rgba(118, 118, 128, 0.12);
}

.rbc-day-bg:hover {
  cursor: pointer;
}

.rbc-toolbar-label {
  visibility: hidden;
}

.rbc-header {
  height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.rbc-button-link {
  color: #8b95a4;
}

.rbc-month-view,
.rbc-time-view {
  border-top: 1px solid #ddd;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  margin-top: 18px;
}

.rbc-toolbar button {
  border: none;
  box-shadow: none;
  padding-left: 40px;
  padding-right: 40px;
}

.rbc-btn-group {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 3px;
}

.rbc-active {
  border-radius: 10px !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-color: white !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  box-shadow: none !important;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rbc-overlay {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 500px;
}